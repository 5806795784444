.container {
  background-color: black;
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to left, #26125b, #bcaaee, #ffffff);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #191654;
}

.loading-slider {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  color: #191654;
}

#svg {
  height: 150px;
  width: 150px;
  stroke: white;
  fill-opacity: 0;
  stroke-width: 1px;
  stroke-dasharray: 4500;
  animation: draw 20s ease;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 4500;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.name-container {
  /* height: 30px; */
  overflow: hidden;
}

.logo-name {
  letter-spacing: 12px;
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: bolder;
}

.preloader {
  /* Add your preloader styles */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white; /* Adjust the background color as needed */
  z-index: 9999;
  opacity: 1;
  visibility: visible;
  transition: opacity 1s, visibility 1s;
}

.preloader.hide {
  opacity: 0;
  visibility: hidden;
}

.lightCyan-slider,
.persianGreen-slider,
.white-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
}
.lightCyan-slider {
  background: #4622aa;
}

.persianGreen-slider {
  background: #7955dd;
}

.white-slider {
  background: #fff;
}

.hide {
  opacity: 0;
  color: #03a6a6;
  font-weight: lighter;
}
