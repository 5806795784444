@media only screen and (min-width: 765px) {
  .header {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .header li {
    float: left;
  }

  .header li a {
    position: relative;
    display: block;
    color: #26125b;
    text-align: center;
    padding: 7px 12px;
    text-decoration: none;
  }

  .header li a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 0;
    height: 4px;
    border-radius: 10px;
    background-color: #4622aa;
    transition: width 0.3s ease-in-out;
  }

  .header li a:hover::after {
    width: 100%;
  }

  .header li a.active::after {
    width: 100%;
  }
}
